import React, { useCallback, useContext, useMemo } from "react";
import styles from './Filters.module.css';
import CloseIcon from "../UI/Icons/CloseIcon/CloseIcon";
import FiltersHeader from "./FiltersHeader/FiltersHeader";
import FiltersForm from "./FiltersForm/FiltersForm";
import { FilterContext } from "../../services/FilterContext";

const Filters = ({ onClose }) => {
    const { filters, setFilters, setFilteredProducts, countFiltered, resetFilters, disabledFilterBtn} = useContext(FilterContext);

    const filtersCount = useMemo(() => {
        if (!filters) return 0;

        let count = Object.values(filters).flat(1).filter(item => item.checked).length;


        if (filters.price_min !== filters.price_current[0]) {
            count++;
        }

        if (filters.price_max !== filters.price_current[1]) {
            count++;
        }


        return count;
    }, [filters]);

    const handleResetFilters = useCallback(() => {
        setFilters({
            ...filters,
            colors: filters.colors.map(color => {
                color.checked = false;

                return color;
            }),
            sizes: filters.sizes.map(size => {
                size.checked = false;

                return size;
            }),
            price_current: [filters.price_min, filters.price_max],
            // trends: filters.trends.map(trend => {
            //     trend.checked = false;

            //     return trend;
            // }),
        })
        resetFilters();
        onClose();
    }, [filters])

    const onChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: filters[e.target.name].map(filter => {
                if (String(filter.id) === e.target.value) {
                    filter.checked = !filter.checked;
                }

                return filter;
            })
        })
    }

    const handlePriceChange = (values) => {
        setFilters({
            ...filters,
            price_current: values
        })
    }

    const watchPriceChange = (values) => {
        setFilters({
            ...filters,
            filter_price: values
        })
    }

    const setFilterAndClose = () => {
        setFilteredProducts();
        onClose();
    }

    return (
        <div className={styles.root}>
            <button
                className={styles.close}
                onClick={onClose}
            >
                <CloseIcon/>
            </button>
            {filters && (
                <div>
                    <FiltersHeader
                        onReset={handleResetFilters}
                        selectedCount={filtersCount}
                    />

                    <FiltersForm
                        handleAcceptFilters={setFilterAndClose}
                        filters={filters}
                        watchPriceChange={watchPriceChange} 
                        handlePriceChange={handlePriceChange}
                        onChange={onChange}
                        findProductsCount={countFiltered}
                        disabledBtn={disabledFilterBtn}
                    />
                </div>
            )}
        </div>
    );
};

export default Filters;